<template>
    <div>
        <b-row class="match-height">

            <b-col md="4">
                <rekap :kabupaten=kabupaten @LoadDataKabupaten=LoadDataKabupaten></rekap>
            </b-col>
            <b-col md="8">

                <statistik :data=statisticsItems :nmKabupaten=nmKabupaten :jm_aduan=jm_aduan></statistik>
            </b-col>
        </b-row>
        <b-row class="match-height">
            <b-col md="4">
                <b-card>
                    <b-button block variant="warning" size="sm" @click="defaultData()">
                        <feather-icon icon="RefreshCwIcon" size='15'></feather-icon> kembali ke data awah
                    </b-button>
                    <rekap_detail :kategoriPengaduan=kategoriPengaduan @detalData=detalData></rekap_detail>
                </b-card>
            </b-col>
            <b-col md="8">
                <b-card>
                    <h4 v-show="kategori !=''">Kategori {{ kategori }}</h4>
                    <table_data :items=items @detailtanggapan=detailtanggapan></table_data>
                </b-card>
            </b-col>
        </b-row>
        <detail_tanggapan :rsDetail=rsDetail></detail_tanggapan>
    </div>
</template>

<script>
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';
    import detail_tanggapan from './component/detail_tanggapan.vue';
    import rekap from './component/rekap.vue';
    import table_data from './component/tabel.vue';
    import {
        BRow,
        BCol,
        BCard,
        BButton
    } from 'bootstrap-vue'
    import Statistik from './component/statistik.vue';
    import Rekap_detail from './component/rekap_detail.vue';
    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BButton,
            rekap,
            table_data,
            Statistik,
            Rekap_detail,
            detail_tanggapan

        },
        data() {
            return {
                status: "",
                id_wilayah: '',
                id_jenis_pengaduan: "",
                items: [],
                rsDetail: {},
                kabupaten: [],
                nmKabupaten: "",
                kategori: '',
                jm_aduan: {},
                kategoriPengaduan: [],
                statisticsItems: [],

            }
        },
        mixins: [Base],
        mounted() {
            this.cek_token();
            this.load_data();
            this.get_kabupaten();
            this.kategori_pengaduan();
            this.jumlah_aduan();
        },
        methods: {
            defaultData() {
                this.status = "";
                this.id_wilayah = '';
                this.id_jenis_pengaduan = "";
                this.kategori = '';
                this.nmKabupaten = '';
                this.jm_aduan = '';

                this.load_data();
                this.get_kabupaten();
                this.kategori_pengaduan();
                this.jumlah_aduan();
            },
            detalData(value) {
                this.id_jenis_pengaduan = value.id_jensi_pengaduan;
                this.kategori = value.jenis_aduan;
                this.load_data();
            },
            detailtanggapan(value) {
                this.rsDetail = value
            },
            LoadDataKabupaten(value) {
                this.id_wilayah = value.id;
                this.nmKabupaten = value.nm_wil;
                this.load_data();
                this.kategori_pengaduan();
                this.jumlah_aduan();
            },
            async jumlah_aduan() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/pengaduan/laporan/jumlah_aduan',
                        data: {
                            status: self.status,
                            id_wilayah: self.id_wilayah,
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.jm_aduan = response.data.result;
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async kategori_pengaduan() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/pengaduan/laporan/kategori_pengaduan',
                        data: {
                            id_wilayah: self.id_wilayah
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.kategoriPengaduan = response.data.result;
                        //self.rs_level = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async get_kabupaten() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/pengaduan/laporan/get_kabupaten',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.kabupaten = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async load_data() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/pengaduan/laporan/load_data',
                        data: {
                            status: self.status,
                            id_wilayah: self.id_wilayah,
                            id_jenis_pengaduan: self.id_jenis_pengaduan
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.items = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
        },


    }
</script>

<style>

</style>