<template>
    <b-card no-body class="card-statistics">
        <b-card-body class="statistics-body mbm-5">
            <b-row class="mtm-2">
                <h5 class="text-primary" v-show="nmKabupaten !='  Tampilkan Semua'">
                    <feather-icon icon="AirplayIcon" size="16"></feather-icon> Rekap Pengaduan {{ nmKabupaten }}
                </h5>
                <h5 class="text-primary" v-show="nmKabupaten =='  Tampilkan Semua'">
                    <feather-icon icon="AirplayIcon" size="16"></feather-icon> Rekap Pengaduan FLLAJ Prov. Jawa Tengah
                </h5>
            </b-row>
            <b-row> 
                <b-col xl="4" sm="6" class="mb-2 mb-xl-0"  >
                    <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar size="48" variant="light-primary">
                                <feather-icon size="24" icon="MessageCircleIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ jm_aduan.total_pengaduan }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                Total Pengaduan
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col> 
                <b-col xl="4" sm="6" class="mb-2 mb-xl-0">
                    <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar size="48" variant="light-primary">
                                <feather-icon size="24" icon="MessageCircleIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ jm_aduan.total_ditanggapi }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                Total Ditanggapi
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>

                <b-col xl="4" sm="6" class="mb-2 mb-xl-0">
                    <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar size="48" variant="light-danger">
                                <feather-icon size="24" icon="AlertTriangleIcon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ jm_aduan.total_pengajuan }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                  Belum Ditanggapi
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
    import {
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BRow,
        BCol,
        BMedia,
        BMediaAside,
        BAvatar,
        BMediaBody,
    } from 'bootstrap-vue'
    import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BCardHeader,
            BCardTitle,
            BCardText,
            BCardBody,
            BMedia,
            BAvatar,
            BMediaAside,
            BMediaBody,
            FeatherIcon,
        },
        props: {
            data: {
                type: Array,
                default: () => [],
            },
            nmKabupaten: {},
            jm_aduan: {}
        },
    }
</script>