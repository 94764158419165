<template>
    <div>
        <b-row>
            <b-col md="3" sm="4" class="my-1">
                <b-form-group class="mb-0">
                    <label class="d-inline-block text-sm-left mr-50"> </label>
                    <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
                </b-form-group>
            </b-col>
            <b-col md="3" sm="8" class="my-1">
            </b-col>
            <b-col md="6" class="my-1">
                <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                    class="mb-0">
                    <b-input-group size="sm">
                        <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Clear
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <b-col cols="12">
                <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                    :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                    :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                    <template #cell(aduan)="data">
                        <b-row class="mlm-5 ">
                            <b-col md="9">
                                <h6><strong class="text-primary">Judul {{ data.item.judul_aduan }}</strong></h6>
                                <h6 v-if="data.item.status !='Ditanggapi'"><small><small class="text-warning">Kategori
                                            {{ data.item.jenis_aduan }}</small>
                                    </small></h6>
                                <h6 v-if="data.item.status =='Ditanggapi'"><small><small class="text-primary">Kategori
                                            {{ data.item.jenis_aduan}}</small>
                                    </small></h6>


                                <p style="text-align:justify"><small>{{ data.item.deskripsi }}</small>
                                </p>
                                <small class="text-primary"> <i>Pelapor {{ data.item.nm_lengkap }} ||
                                        <a :href="'https://wa.me/'+ data.item.cp"
                                            target="_blank">{{ data.item.no_hp }}</a></i></small>

                            </b-col>
                            <b-col md="3">
                                <h6><small>Lokasi</small></h6>
                                <h6><small><strong>
                                            <feather-icon icon="MapPinIcon" size="15" />
                                            {{ data.item.nm_wil }}</strong></small></h6>
                                <h6><small>Tanggal Pengaduan</small></h6>
                                <h6><small><strong>{{ data.item.dc }}</strong></small></h6>
                                <b-button v-b-modal.modal-2 block variant="primary" size="sm"
                                    v-if="data.item.status =='Ditanggapi'" @click="detailtanggapan(data.item)">
                                    <feather-icon icon="CheckCircleIcon" size="15" /> Ditanggapi</b-button>
                                <b-button v-b-modal.modal-2 block variant="warning" size="sm"
                                    v-if="data.item.status !='Ditanggapi'" @click="detailtanggapan(data.item)">
                                    <feather-icon icon="AlertCircleIcon" size="15" /> Belum Ditanggapi</b-button>

                            </b-col>
                        </b-row>
                    </template>
                </b-table>
            </b-col>


        </b-row>
        <hr>
        <b-row>
            <b-col cols="6">
                Total Row {{ items.length }}
            </b-col>
            <b-col cols="6">
                <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right"
                    size="sm" class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        props: {
            items: {}
        },
        emits :["detailtanggapan"],
        data() {
            return {
                perPage: 3,
                pageOptions: [3, 5, 10, 50, 100, 1000, 2000, 100000],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                fields: [{
                    key: 'aduan',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }],

            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() { 
            this.totalRows = this.items.length
        },
        methods: {
            detailtanggapan(value){  
                this.$emit('detailtanggapan', value)
            },
            onFiltered(filteredItems) { 
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>