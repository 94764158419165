<template>
    <div>
        <b-row>
            <b-col md="12">
                <b-card class="card-congratulations bg-primary match-height" @click="act_data('')">
                    <b-row>
                        <b-col md="12">
                            <h5 class="text-warning">Pilih Kabupaten/Kota
                            </h5>
                            <b-form-group>
                                <v-select v-model="selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    class="bg-white" @input="LoadDataKabupaten(selected)" label="nm_wil"
                                    :options="kabupaten" />
                            </b-form-group>
                        </b-col> 
                         
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BButton,
        BFormGroup
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BButton,
            BFormGroup,
            vSelect

        },
        props: {
            kabupaten: {}
        },
        emits: ["LoadDataKabupaten"],

        data() {
            return {
                selected: {
                    title: 'Square'
                },
            }
        },
        methods: {
            LoadDataKabupaten(value) {
                this.$emit("LoadDataKabupaten", value)
            }
        }

    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>