<template>
    <b-row> 
        <b-col md="12" class="my-1">
            <b-form-group label="" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                    <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''">
                            Clear
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </b-col>

        <b-col cols="12">
            <b-table hover responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(aduan)="data"> 
                    <b-row class="mlm-5 " @click="detalData(data.item)">
                        <b-col md="3">
                            <b-avatar size="60" rounded="" variant="light-primary"><span style="font-size:15px">{{ data.item.jm }}</span></b-avatar>

                        </b-col>
                        <b-col md="9"> 
                            <h6>{{ data.item.jenis_aduan }}</h6>
                            <small>Ditanggapi <b class="text-primary">{{ data.item.jm }}</b><br>Belum Ditanggapi <b class="text-warning">{{ parseInt(data.item.jm) - parseInt(data.item.jm_ditanggapi) }}</b></small>
                        </b-col>

                    </b-row>
                </template>
            </b-table>
        </b-col>

        <b-col cols="6">
            Total Row {{ items.length }}
        </b-col>
        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
        },
        props:{
            kategoriPengaduan : {}
        },
        emits:["detalData"],
        data() {
            return {
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                fields: [{
                    key: 'aduan',
                    label: '',
                    thStyle: {
                        display: 'none'
                    }
                }], 

            }
        },
        
        computed: {
            items: {
                get: function () {
                    return this.kategoriPengaduan;
                },
                set: function (newValue) {
                    return this.kategoriPengaduan = newValue;
                },
            },

            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
        },
        methods: {
            detalData(value){
                this.$emit("detalData", value);
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>